<script>
	import { vocab } from "./store.js";
	import { username } from "./store.js";
	import { useremail } from "./store.js";
	import { appstatus } from "./store.js";
	import { createEventDispatcher } from "svelte";

	const dispatch = createEventDispatcher();

	let message;

	function close() {
		console.log("close");
		$appstatus = null;
	}

	function sendEmail(e) {
		e.preventDefault();

		if ($username && $useremail && message) {
			const newmsg = {
				c: "email",
				username: $username,
				useremail: $useremail,
				message,
			};
			$appstatus = "emailing_email_sending";
			dispatch("data", newmsg);

			setTimeout(() => {
				if ($appstatus == "emailing_email_sending") {
					console.log("$appstatus = 'emailing_email_failed';");
					$appstatus = "emailing_email_failed";
				}
			}, 5000);
		}
	}
</script>

<div class="z-livechat-inner z-livechat-email">
	<div class="z-livechat-email-header">
		{$vocab.sendUsMessage}
	</div>
	<form on:submit={sendEmail}>
		<div>
			<input
				bind:value={$username}
				placeholder="Name"
				type="text"
				name="name"
				required
				autofocus
				autocomplete="off"
			/>
		</div>
		<div>
			<input
				bind:value={$useremail}
				placeholder="Email"
				type="email"
				name="email"
				required
				autocomplete="off"
			/>
		</div>
		<div>
			<textarea
				bind:value={message}
				placeholder="Message"
				name="message"
				required
			/>
		</div>
		<div class="center">
			{#if $appstatus == "emailing_email_sent"}
				<button on:click={close} type="button" class="green"
					>{$vocab.emailSentClose}</button
				>
			{:else if $appstatus == "emailing_email_failed"}
				<p class="error">{$vocab.emailFailed}</p>
				<button>{$vocab.send}</button>
			{:else if $appstatus == "emailing_email_sending"}
				<p class="black">{$vocab.sending}</p>
			{:else}
				<button>{$vocab.send}</button>
			{/if}
		</div>
	</form>
</div>

<style type="text/css">
	::placeholder {
		color: #999;
	}
	.z-livechat-email-header {
		background: #49bded;
		padding: 20px;
		margin-bottom: 20px;
		line-height: 1.6;
		text-align: center;
		position: relative;
	}
	.z-livechat-email form button {
		color: #fff;
		background: #1aaae7;
		width: 100%;
		margin: auto;
		border-radius: 4px;
		padding: 10px 20px;
		border: none;
		display: block;
		font-family: sans-serif;
		font-size: 1em;
	}

	.z-livechat-email form textarea {
		height: 80px;
	}

	.z-livechat-email form input,
	.z-livechat-email form textarea {
		width: 100%;
		padding: 10px;
		border-radius: 3px;
		border: solid 1px #aaa;
		outline: none;
		color: #000;
		box-sizing: border-box;
		font-family: sans-serif;
		font-size: 1em;
	}
	.z-livechat-email form > div {
		margin-bottom: 20px;
	}
	.z-livechat-email form {
		display: block;
		width: 90%;
		margin: 0 auto;
	}
	.z-livechat-email {
		background: linear-gradient(#359fef, #dbf2ff);
		background: #fff;
		text-align: left;
		font-weight: normal;
		color: #fff;
	}
</style>
