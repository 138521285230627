<script>
	import ProgressCircle from "svelte-progresscircle-ilkkah";
	import { createEventDispatcher } from "svelte";

	const dispatch = createEventDispatcher();

	export let endpoint;
	export let maxFileSize = 3145728;
	export let allowedFileTypes = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp', 'webm', 'mkv', 'flv', 'flv', 'vob', 'ogv', 'ogg', 'drc', 'gifv', 'mng', 'avi', 'mov', 'qt', 'wmv', 'yuv', 'rm', 'rmvb', 'asf', 'amv', 'mp4', 'm4p', 'm4v', 'mpg', 'mp2', 'mpeg', 'mpe', 'mpv', '3gp', '3g2', 'mxf', 'roq', 'nsv', 'flv', 'f4v', 'f4p', 'f4a', 'f4b', 'mp3', 'ogg', 'opus', 'pdf', 'txt'];

	let uploader;
	let uploading = false;
	let processing = false;
	let progress = 0;

	function reset() {
		uploading = false;
		processing = false;
		progress = 0;
	}

	function getFileExtension(file) {
		const filenameParts = file.name.split(".");
		return filenameParts[filenameParts.length - 1].toLowerCase();
	}

	function validateUpload(file) {
		if (!file) {
			return false;
		}

		if (file.size > maxFileSize) {
			dispatch("error", {
				message: `Maximum file size is ${
					maxFileSize / (1024 * 1024)
				}mb`,
			});
			return false;
		}

		if (!allowedFileTypes.includes(getFileExtension(file))) {
			dispatch("error", {
				message: `Allowed file types are images, videos and zip files.`,
			});
			return false;
		}

		return true;
	}

	function onError(e) {
		dispatch("error", {
			message: `We were unable to upload your photo, ${e.message}. Please try again.`,
		});
	}

	function onProgress(e) {
		if (e.lengthComputable) {
			progress = Math.round((e.loaded * 100) / e.total);
		}
	}

	async function upload(attempt = 0) {
		if (attempt > 5) {
			onError(
				new Error("Upload failed after 5 retries. Please try later.")
			);
			return;
		}

		const file = uploader.files[0];

		if (!validateUpload(file)) {
			return;
		}

		uploading = true;
		const { urls, method } = await endpoint(file);
		const reader = new FileReader();
		const xhr = new XMLHttpRequest();

		xhr.upload.onprogress = onProgress;

		xhr.onload = () => {
			uploading = false;
			processing = true;
			dispatch("uploaded", { url: urls.publicUrl, file });
		};

		xhr.onloadend = reset;
		xhr.onerror = onError;

		xhr.open(method, urls.signedUrl, true);
		xhr.timeout = 15000;
		xhr.setRequestHeader("Content-Type", file.type);

		xhr.upload.addEventListener(
			"timeout",
			() => {
				upload(++attempt);
			},
			false
		);

		reader.onload = function (evt) {
			xhr.send(evt.target["result"]);
		};

		reader.readAsArrayBuffer(file);
	}
</script>

<label for="fileinput" class="button-attach-file">
	{#if uploading || processing}
		<ProgressCircle max="100" value={progress}>
			<span>{progress}</span>
		</ProgressCircle>    
	{:else}
		<svg class="z-livechat-svg-icon"><use xlink:href="#z-livechat-icon-file" /></svg>
	{/if}
	<input
		id="fileinput"
		bind:this={uploader}
		type="file"
		class="visually-hidden"
		class:busy={uploading}
		on:change={upload}
	/>
</label>

<style>
	label.button-attach-file {
        margin-right: 5px;
		stroke: none !important;
	}
</style>
