import { writable } from 'svelte/store';

export const appstatus = writable();
export const admin_available = writable(false);
export const server_contacted = writable(false);
export const online = writable(false);
export const waiting_in_line_count = writable(0);
export const userid = writable('');
export const chatid = writable('');
export const host = writable('');
export const pid = writable('');
export const username = writable('');
export const useremail = writable('');
export const messages = writable([]);
export const msg = writable('');
export const feedbackChatId = writable(null);
export const feedbackSuccess = writable(null);
export const feedbackText = writable('');
export const signedurl = writable('');
export const vocab = writable({});

