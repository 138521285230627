import App from './App.svelte';
import { SvelteComponent } from 'svelte'

// customElements.define('aamu-livechat-'+(''+Math.random()).replace('.', '')+(''+Math.random()).replace('.', ''), App);

// const app = new App({
// 	target: document.body
// });

// export default app;

if (!customElements.get('aamu-livechat')) {
	customElements.define(
	// I recommend prefixing your custom elements, but for this example
	// I'm keeping it simple.
	'aamu-livechat',
	class extends HTMLElement {
		
		constructor() {
		super()
	
		// Create the shadow root.
		const shadowRoot = this.attachShadow({ mode: 'open' })
	
		// Instantiate the Svelte Component
		this._element = new App({
			// Tell it that it lives in the shadow root
			target: shadowRoot
		})
		}
		disconnectedCallback() {
		// Destroy the Svelte component when this web component gets
		// disconnected. If this web component is expected to be moved
		// in the DOM, then you need to use `connectedCallback()` and
		// set it up again if necessary.
		this._element?.$destroy();
		}
	}
	)
}