<script>
	import { vocab } from "./store.js";
	import { waiting_in_line_count } from "./store.js";
	import { online } from "./store.js";
	import { feedbackText } from "./store.js";
	import { feedbackSuccess } from "./store.js";
	import { messages } from "./store.js";
	import { msg } from "./store.js";
	import { appstatus } from "./store.js";
	import { createEventDispatcher } from "svelte";
	import FileUploader from "./FileUploader.svelte";

	const dispatch = createEventDispatcher();
	let sending = false;
	let chatWindowMaximized = false;

	function mounted(panel) {
		const scroll = (value) => {
			setTimeout(() => {
				panel.scrollTo(0, panel.scrollHeight);
			}, 0);
		};

		messages.subscribe(scroll);
	}

	function sendFeedback(e) {
		e.preventDefault();
		const newmsg = {
			c: "feedback",
			id: Date.now(),
			success: $feedbackSuccess,
			text: $feedbackText,
		};
		dispatch("feedback", newmsg);
		sending = true;
		setTimeout(() => {
			console.log("$appstatus = 'null';");
			$appstatus = null;
		}, 2500);
	}

	function keydown(e) {
		const isEnter = "key" in e ? e.key === "Enter" : e.keyCode === 13;

		if (isEnter && !e.shiftKey) {
			handleSubmit(e);
		}
	}

	function sendMessage(msg, e) { 
		const newmsg = { c: "msg", msg, id: Date.now() };
		$messages.push(newmsg);
		dispatch("data", newmsg);

		if (e) {
			e.target.parentNode.querySelector("#z-livechat-input").focus();
		}		
	}

	function handleSubmit(e) {
		e.preventDefault();

		if ($msg) {
			sendMessage($msg, e);
			$msg = "";
		}
	}

	function toggleSize() {
		chatWindowMaximized = !chatWindowMaximized;
	}

	async function getSignedUrl(file) {
		const resolvefn = (value) => {
		};
		
		const rejectfn = (value) => {
		};

		const promise = new Promise((resolvefn, rejectfn) => {
			dispatch("getSignedUrl", {
				file: {
					name: file.name,
					type: file.type,
					size: file.size
				},
				resolvefn,
				rejectfn
			});
		});

		return promise;
	}

	function uploaded({ detail }) {
		sendMessage('📎 '+detail.url);
	}

	function onError({ detail }) {
		const newmsg = { c: "msg", msg: detail.message, id: Date.now(), error: true };
		$messages.push(newmsg);
		dispatch("data", newmsg);
	}

</script>

<div
	class:z-livechat-is-offline={$online == false}
	class:z-livechat-is-ended={$appstatus == "chat_ended"}
	class:z-livechat-inner-maximized={chatWindowMaximized}
	class="z-livechat-inner z-livechat-status-{$appstatus}"
>
	{#if $appstatus == "chat_ended"}
		{#if sending}
			<p>
				{$vocab.feedbackSent}<br /><br /><br /><br /><br /><br /><br
				/><br /><br /><br /><br /><br />
			</p>
		{:else}
			<p>{$vocab.didYouGet}<br /><br /></p>
			<form class="rating-form" on:submit={sendFeedback}>
				<p>
					<label for="sad">
						<input
							bind:group={$feedbackSuccess}
							type="radio"
							name="rating"
							class="sad"
							id="sad"
							value="sad"
						/>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 36 36"
							><path
								fill="#FFCC4D"
								d="M36 18c0 9.941-8.059 18-18 18-9.94 0-18-8.059-18-18C0 8.06 8.06 0 18 0c9.941 0 18 8.06 18 18"
							/><ellipse
								fill="#664500"
								cx="11.5"
								cy="14.5"
								rx="2.5"
								ry="3.5"
							/><ellipse
								fill="#664500"
								cx="24.5"
								cy="14.5"
								rx="2.5"
								ry="3.5"
							/><path
								fill="#664500"
								d="M8.665 27.871c.178.161.444.171.635.029.039-.029 3.922-2.9 8.7-2.9 4.766 0 8.662 2.871 8.7 2.9.191.142.457.13.635-.029.177-.16.217-.424.094-.628C27.3 27.029 24.212 22 18 22s-9.301 5.028-9.429 5.243c-.123.205-.084.468.094.628z"
							/></svg
						>
					</label>
					<label for="neutral">
						<input
							bind:group={$feedbackSuccess}
							type="radio"
							name="rating"
							class="neutral"
							id="neutral"
							value="neutral"
						/>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 36 36"
							><path
								fill="#FFCC4D"
								d="M36 18c0 9.941-8.059 18-18 18-9.94 0-18-8.059-18-18C0 8.06 8.06 0 18 0c9.941 0 18 8.06 18 18"
							/><ellipse
								fill="#664500"
								cx="11.5"
								cy="16.5"
								rx="2.5"
								ry="3.5"
							/><ellipse
								fill="#664500"
								cx="24.5"
								cy="16.5"
								rx="2.5"
								ry="3.5"
							/><path
								fill="#664500"
								d="M25 26H11c-.552 0-1-.447-1-1s.448-1 1-1h14c.553 0 1 .447 1 1s-.447 1-1 1z"
							/></svg
						>
					</label>
					<label for="happy">
						<input
							bind:group={$feedbackSuccess}
							type="radio"
							name="rating"
							class="happy"
							id="happy"
							value="happy"
						/>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 36 36"
							><circle
								fill="#FFCC4D"
								cx="18"
								cy="18"
								r="18"
							/><path
								fill="#664500"
								d="M10.515 23.621C10.56 23.8 11.683 28 18 28c6.318 0 7.44-4.2 7.485-4.379.055-.217-.043-.442-.237-.554-.195-.111-.439-.078-.6.077C24.629 23.163 22.694 25 18 25s-6.63-1.837-6.648-1.855C11.256 23.05 11.128 23 11 23c-.084 0-.169.021-.246.064-.196.112-.294.339-.239.557z"
							/><ellipse
								fill="#664500"
								cx="12"
								cy="13.5"
								rx="2.5"
								ry="3.5"
							/><ellipse
								fill="#664500"
								cx="24"
								cy="13.5"
								rx="2.5"
								ry="3.5"
							/></svg
						>
					</label>
				</p>
				<p>
					<textarea
						class="z-livechat-textarea"
						autofocus
						bind:value={$feedbackText}
					/>
				</p>
				<p><button>{$vocab.send}</button></p>
			</form>
		{/if}
	{:else}
		<div class="z-livechat-header">
			<svg
				class="z-livechat-svg-icon z-livechat-svg-icon-small z-livechat-svg-icon-left"
				><use xlink:href="#z-livechat-icon-person" /></svg
			> <span>{$vocab.header}</span>
			<svg
				class="z-livechat-svg-icon z-livechat-svg-icon-smallest"
				style="cursor:pointer; position: absolute; right: 0;"
				on:click={toggleSize}
			>
				{#if chatWindowMaximized}
					<use xlink:href="#z-livechat-icon-minimize" />
				{:else}
					<use xlink:href="#z-livechat-icon-maximize" />
				{/if}
			</svg>
		</div>
		{#if $appstatus == "chat_waiting"}
			<div class="z-livechat-waiting">
				{$vocab.inLine} #{$waiting_in_line_count}
			</div>
		{/if}
		<div
			class="z-livechat-messages"
			id="z-livechat-messages-panel"
			use:mounted
		>
			<ul id="z-livechat-messages" class="z-livechat-message-list">
				{#each $messages as msg, i (msg.id)}
					{#if msg.admin && (i === 0 || ($messages[i - 1] && !$messages[i - 1].admin))}
						<li class="z-livechat-from-admin z-livechat-from-name">
							{msg.adminName || ""}
						</li>
					{/if}
					{#if msg.error}
						<li class="z-livechat-message-error">
							<div>
								<p>{msg.msg}</p>
							</div>
						</li>
					{:else}
						<li class:z-livechat-from-admin={msg.admin}>
							<div>
								{#if msg.html}
									{@html msg.html}
								{:else}
									<p>{msg.msg}</p>
								{/if}
							</div>
						</li>
					{/if}
				{/each}
			</ul>
		</div>
		<div class="z-livechat-input">
			<form on:submit={handleSubmit} class="z-livechat-form">
				<textarea
					on:keydown={keydown}
					id="z-livechat-input"
					type="text"
					bind:value={$msg}
					class="z-livechat-input"
					autofocus
				/>
				<div class="z-livechat-textarea-buttons">
					<FileUploader endpoint={getSignedUrl} on:uploaded={uploaded} on:error={onError} />
					<button class="button-send-message"
						><svg class="z-livechat-svg-icon"
							><use xlink:href="#z-livechat-icon-send" /></svg
						></button
					>
				</div>
			</form>
		</div>
	{/if}
	<img class="emoji hidden" alt="Dirty workaround to keep css definition" />
</div>
