<svelte:options tag={null} />

<script>
	import { admin_available } from "./store.js";
	import { server_contacted } from "./store.js";
	import { vocab } from "./store.js";
	import { appstatus } from "./store.js";
	import Livechat from "./Livechat.js";
	import Chat from "./Chat.svelte";
	import Email from "./Email.svelte";

	const app = new Livechat();

	function sendData({ detail }) {
		app.send(detail);
	}

	function sendFeedback({ detail }) {
		app.sendFeedback(detail);
	}

	function getSignedUrl({ detail }) {
		app.getSignedUrl(detail);
	}

	function toggle() {
		if (
			$appstatus == "chat_waiting" ||
			$appstatus == "chat_active" ||
			$appstatus == "chat_ended"
		) {
			if ($appstatus == "chat_ended") {
				$appstatus = null;
			} else {
				const newmsg = { c: "endchat", id: Date.now() };
				app.send(newmsg);
			}
		} else if (
			$appstatus == "emailing" ||
			$appstatus == "emailing_email_sending" ||
			$appstatus == "emailing_email_sent" ||
			$appstatus == "emailing_email_failed"
		) {
			$appstatus = null;
		} else if ($admin_available) {
			app.send({ c: "reg" });
			$appstatus = "chat_waiting";
		} else {
			$appstatus = "emailing";
		}
	}
</script>

<svg
	aria-hidden="true"
	style="position: absolute; width: 0; height: 0; overflow: hidden;"
	version="1.1"
	xmlns="http://www.w3.org/2000/svg"
	xmlns:xlink="http://www.w3.org/1999/xlink"
>
	<defs>
		<symbol id="z-livechat-icon-chat" viewBox="0 0 64 64">
			<g id="Layer_1"
				><g><circle cx="32" cy="32" fill="#77B3D4" r="32" /></g><g
					opacity="0.2"
					><path
						d="M52,32c0-9.941-8.954-18-20-18s-20,8.059-20,18c0,9.569,8.303,17.372,18.775,17.944    C31.463,53.597,32,56,32,56s5.045-3.044,9.639-8.23C47.814,44.706,52,38.795,52,32z"
						fill="#231F20"
					/></g
				><g
					><path
						d="M49,28.8C49,43.763,32,54,32,54s-9.389-42,0-42S49,19.522,49,28.8z"
						fill="#FFFFFF"
					/></g
				><g
					><ellipse
						cx="32"
						cy="30"
						fill="#FFFFFF"
						rx="20"
						ry="18"
					/></g
				><g><circle cx="32" cy="30" fill="#4F5D73" r="2" /></g><g
					><circle cx="40" cy="30" fill="#4F5D73" r="2" /></g
				><g><circle cx="24" cy="30" fill="#4F5D73" r="2" /></g></g
			><g id="Layer_2" />
		</symbol>
		<symbol id="z-livechat-icon-close" viewBox="0 0 512 512">
			<path
				d="M278.6 256l68.2-68.2c6.2-6.2 6.2-16.4 0-22.6-6.2-6.2-16.4-6.2-22.6 0L256 233.4l-68.2-68.2c-6.2-6.2-16.4-6.2-22.6 0-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3l68.2 68.2-68.2 68.2c-3.1 3.1-4.7 7.2-4.7 11.3 0 4.1 1.6 8.2 4.7 11.3 6.2 6.2 16.4 6.2 22.6 0l68.2-68.2 68.2 68.2c6.2 6.2 16.4 6.2 22.6 0 6.2-6.2 6.2-16.4 0-22.6L278.6 256z"
			/>
		</symbol>
		<symbol id="z-livechat-icon-person" viewBox="0 0 512 512">
			<path
				d="M256 48C148.5 48 60.1 129.5 49.2 234.1c-.8 7.2-1.2 14.5-1.2 21.9 0 7.4.4 14.7 1.2 21.9C60.1 382.5 148.5 464 256 464c114.9 0 208-93.1 208-208S370.9 48 256 48zm135.8 326.1c-22.7-8.6-59.5-21.2-82.4-28-2.4-.7-2.7-.9-2.7-10.7 0-8.1 3.3-16.3 6.6-23.3 3.6-7.5 7.7-20.2 9.2-31.6 4.2-4.9 10-14.5 13.6-32.9 3.2-16.2 1.7-22.1-.4-27.6-.2-.6-.5-1.2-.6-1.7-.8-3.8.3-23.5 3.1-38.8 1.9-10.5-.5-32.8-14.9-51.3-9.1-11.7-26.6-26-58.5-28h-17.5c-31.4 2-48.8 16.3-58 28-14.5 18.5-16.9 40.8-15 51.3 2.8 15.3 3.9 35 3.1 38.8-.2.7-.4 1.2-.6 1.8-2.1 5.5-3.7 11.4-.4 27.6 3.7 18.4 9.4 28 13.6 32.9 1.5 11.4 5.7 24 9.2 31.6 2.6 5.5 3.8 13 3.8 23.6 0 9.9-.4 10-2.6 10.7-23.7 7-58.9 19.4-80 27.8C91.6 341.4 76 299.9 76 256c0-48.1 18.7-93.3 52.7-127.3S207.9 76 256 76c48.1 0 93.3 18.7 127.3 52.7S436 207.9 436 256c0 43.9-15.6 85.4-44.2 118.1z"
			/>
		</symbol>
		<symbol id="z-livechat-icon-email" viewBox="0 0 64 64">
			<g id="Layer_1"
				><g><circle cx="32" cy="32" fill="#77B3D4" r="32" /></g><g
					><g opacity="0.2"
						><path
							d="M52,44c0,2.209-1.791,4-4,4H16c-2.209,0-4-1.791-4-4V24c0-2.209,1.791-4,4-4h32c2.209,0,4,1.791,4,4V44z"
							fill="#231F20"
						/></g
					><g
						><path
							d="M52,42c0,2.209-1.791,4-4,4H16c-2.209,0-4-1.791-4-4V22c0-2.209,1.791-4,4-4h32c2.209,0,4,1.791,4,4V42z"
							fill="#E0E0D1"
						/></g
					><g opacity="0.2"
						><g
							><path
								d="M35.524,30.185c-1.938-2.146-5.109-2.146-7.047,0L13.05,43.228c-0.18,0.199-0.328,0.393-0.461,0.585      C13.29,45.119,14.549,46,16,46h32c1.451,0,2.71-0.881,3.411-2.187c-0.133-0.191-0.281-0.385-0.461-0.585L35.524,30.185z"
								fill="#231F20"
							/></g
						></g
					><g
						><g
							><path
								d="M35.524,32c-1.938-1.9-5.109-1.9-7.047,0L13.05,43.546c-0.18,0.176-0.328,0.348-0.461,0.517      C13.29,45.22,14.549,46,16,46h32c1.451,0,2.71-0.78,3.411-1.936c-0.133-0.169-0.281-0.341-0.461-0.517L35.524,32z"
								fill="#E0E0D1"
							/></g
						></g
					><g opacity="0.2"
						><g
							><path
								d="M12.589,20.187C13.29,18.881,14.549,18,16,18h32c1.451,0,2.71,0.881,3.411,2.187      c-0.133,0.191-0.281,0.385-0.461,0.585L35.524,33.815c-1.938,2.146-5.109,2.146-7.047,0L12.589,20.187z"
								fill="#231F20"
							/></g
						></g
					><g
						><g
							><path
								d="M28.476,32c1.938,1.9,5.109,1.9,7.047,0L50.95,20.454c0.18-0.176,0.328-0.348,0.461-0.517      C50.71,18.78,49.451,18,48,18H16c-1.451,0-2.71,0.78-3.411,1.936c0.133,0.169,0.281,0.341,0.461,0.517L28.476,32z"
								fill="#FFFFFF"
							/></g
						></g
					></g
				></g
			><g id="Layer_2" />
		</symbol>
		<symbol id="z-livechat-arrow-down" viewBox="0 0 512 512">
			<path
				d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"
			/>
		</symbol>
		<symbol id="z-livechat-icon-send" viewBox="0 0 24 24">
			<g id="info" /><g id="icons"
				><path
					d="M21.5,11.1l-17.9-9C2.7,1.7,1.7,2.5,2.1,3.4l2.5,6.7L16,12L4.6,13.9l-2.5,6.7c-0.3,0.9,0.6,1.7,1.5,1.2l17.9-9   C22.2,12.5,22.2,11.5,21.5,11.1z"
					id="send"
				/></g
			>
		</symbol>
		<symbol id="z-livechat-icon-expand" viewBox="0 0 32 32">
			<path
				d="M31.775,31.638C31.533,31.88,31.217,32,30.9,32c-0.314,0-0.631-0.12-0.873-0.362l-9.975-9.977v5.758  c0,0.682-0.553,1.235-1.236,1.235s-1.236-0.554-1.236-1.235v-8.741c0-0.682,0.553-1.235,1.236-1.235h8.738  c0.684,0,1.236,0.553,1.236,1.235s-0.553,1.237-1.236,1.237h-5.756l9.977,9.976C32.258,30.374,32.258,31.156,31.775,31.638z   M13.32,3.483c-0.682,0-1.234,0.553-1.234,1.236v5.756L1.971,0.362c-0.48-0.482-1.264-0.482-1.746,0  c-0.482,0.483-0.482,1.265,0,1.749l10.113,10.113H4.582c-0.684,0-1.236,0.553-1.236,1.236c0,0.682,0.553,1.236,1.236,1.236h8.738  c0.684,0,1.236-0.554,1.236-1.236V4.72C14.557,4.037,14.004,3.483,13.32,3.483z"
			/>
		</symbol>
		<symbol id="z-livechat-icon-maximize" viewBox="0 0 32 32">
			<path
				d="M14.075,17.789c0.478,0.479,0.478,1.254,0,1.732l-9.89,9.891h5.705c0.678,0,1.225,0.549,1.225,1.227  s-0.547,1.225-1.225,1.225H1.226C0.549,31.863,0,31.316,0,30.639v-8.666c0-0.676,0.549-1.225,1.226-1.225  c0.678,0,1.227,0.549,1.227,1.225v5.709l9.89-9.893c0.239-0.238,0.553-0.359,0.865-0.359C13.521,17.43,13.835,17.551,14.075,17.789z   M30.774,0.137H22.11c-0.678,0-1.226,0.547-1.226,1.225s0.548,1.227,1.226,1.227h5.705L17.79,12.615  c-0.479,0.479-0.479,1.254,0,1.732c0.478,0.479,1.253,0.479,1.732,0L29.548,4.32v5.707c0,0.676,0.549,1.225,1.227,1.225  c0.677,0,1.226-0.549,1.226-1.225V1.361C32,0.684,31.451,0.137,30.774,0.137z"
			/>
		</symbol>
		<symbol id="z-livechat-icon-minimize" viewBox="0 0 32 32">
			<path
				d="M14.436,18.655v8.665c0,0.677-0.549,1.226-1.227,1.226s-1.227-0.548-1.227-1.226v-5.707l-9.891,9.891  c-0.238,0.239-0.553,0.359-0.867,0.359c-0.313,0-0.625-0.12-0.865-0.359c-0.479-0.479-0.479-1.254,0-1.733l9.891-9.892H4.543  c-0.678,0-1.227-0.548-1.227-1.225c0-0.676,0.549-1.226,1.227-1.226h8.666C13.887,17.43,14.436,17.979,14.436,18.655z M31.641,0.495  c-0.477-0.479-1.252-0.479-1.732,0L19.883,10.522V4.815c0-0.677-0.549-1.225-1.227-1.225c-0.676,0-1.225,0.548-1.225,1.225v8.665  c0,0.677,0.549,1.226,1.225,1.226h8.664c0.678,0,1.227-0.549,1.227-1.226s-0.549-1.225-1.227-1.225h-5.705L31.641,2.229  C32.119,1.75,32.119,0.975,31.641,0.495z"
			/>
		</symbol>
		<symbol id="z-livechat-icon-file" viewBox="0 0 16 16">
			<path
				d="M7.646,13.646l0.707,0.707l6-6c1.573-1.573,1.573-4.134,0-5.707c-1.574-1.574-4.133-1.574-5.707,0l-6.5,6.5  c-1.298,1.298-1.298,3.409,0,4.707c0.648,0.648,1.501,0.974,2.354,0.974s1.705-0.325,2.354-0.974l6-6  C13.349,7.358,13.621,6.7,13.621,6s-0.272-1.358-0.768-1.854c-0.99-0.99-2.717-0.99-3.707,0l-5.5,5.5l0.707,0.707l5.5-5.5  c0.611-0.611,1.682-0.611,2.293,0C12.452,5.159,12.621,5.566,12.621,6s-0.169,0.841-0.475,1.146l-6,6  c-0.908,0.908-2.385,0.908-3.293,0s-0.908-2.385,0-3.293l6.5-6.5c1.184-1.184,3.109-1.184,4.293,0s1.184,3.109,0,4.293L7.646,13.646  z"
			/>
		</symbol>
	</defs>
</svg>

<div class="z-livechat">
	{#if $server_contacted && $vocab}
		{#if $appstatus == "emailing" || $appstatus == "emailing_email_sending" || $appstatus == "emailing_email_sent" || $appstatus == "emailing_email_failed"}
			<Email on:data={sendData} />
		{:else if $appstatus == "chat_waiting" || $appstatus == "chat_active" || $appstatus == "chat_ended"}
			<Chat on:data={sendData} on:feedback={sendFeedback} on:getSignedUrl={getSignedUrl} />
		{/if}

		<div
			class="z-livechat-btn z-livechat-btn-abs"
			on:click={() => toggle()}
		>
			{#if $appstatus == "chat_waiting" || $appstatus == "chat_active" || $appstatus == "chat_ended"}
				{#if $vocab.endChat}<span>{$vocab.endChat}</span> {/if}<svg
					class="z-livechat-svg-icon"
					><use xlink:href="#z-livechat-icon-close" /></svg
				>
			{:else if $appstatus == "emailing" || !$admin_available}
				<svg class="z-livechat-svg-icon"
					><use xlink:href="#z-livechat-icon-email" /></svg
				>
			{:else}
				{#if $vocab.startChat}<span>{$vocab.startChat}</span> {/if}<svg
					class="z-livechat-svg-icon"
					><use xlink:href="#z-livechat-icon-chat" /></svg
				>
			{/if}
		</div>
	{/if}
</div>

<style global>
	:global(.green) {
		background: #15bb6f !important;
	}
	:global(.error) {
		color: red;
	}
	
	:global(.black) {
		color: #000;
	}
	
	:global(.center) {
		text-align: center;
	}

	:global(.z-livechat textarea),
	:global(.z-livechat input) {
		box-sizing: border-box;
		border-width: 1px;
	}

	:global(.z-livechat) {
		line-height: 1.5;
		z-index: 99999999;
		font-family: sans-serif;
		transition: 0.3s all;
		position: fixed;
		bottom: 0;
		right: 20px;
	}

	:global(.z-livechat-textarea) {
		height: 100px;
		width: 90%;
		margin: auto;
		border-radius: 3px;
		outline: none;
		font-family: sans-serif;
		font-size: 15px;
		line-height: 1.3;
		color: #333;
		font-weight: normal;
	}

	:global(.z-livechat-svg-icon-btn) {
		cursor: pointer;
	}

	:global(.z-livechat-svg-icon-left) {
		float: left;
	}

	:global(.z-livechat-svg-icon-right) {
		float: right;
	}

	:global(.z-livechat-svg-icon.z-livechat-svg-icon-abs-right-round) {
		position: absolute;
		top: 3px;
		right: 3px;
		fill: #fff;
		border-radius: 100%;
		border: solid 1px #ffffff8c;
		width: 20px;
		height: 20px;
	}

	:global(.z-livechat-svg-icon.z-livechat-svg-icon-small) {
		width: 30px;
		height: 30px;
	}

	:global(.z-livechat-svg-icon.z-livechat-svg-icon-smallest) {
		width: 20px;
		height: 20px;
	}

	:global(.z-livechat-svg-icon) {
		display: block;
		width: 35px;
		height: 35px;
	}

	:global(.z-livechat ::-webkit-scrollbar-thumb) {
		background: #ccc;
		border-radius: 5px;
	}

	:global(.z-livechat ::-webkit-scrollbar) {
		width: 7px;
	}

	:global(.z-livechat .z-livechat-btn svg) {
		fill: #448dd6;
		margin: 12px 0 10px 0;
		float: right;
	}

	:global(.z-livechat .z-livechat-btn span) {
		display: inline-block;
		word-break: keep-all;
		white-space: pre;
	}

	:global(.z-livechat .z-livechat-btn-abs:hover) {
		box-shadow: 0 2px 25px rgba(0, 0, 0, 0.2);
	}

	:global(.z-livechat .z-livechat-btn.z-livechat-btn-abs) {
		position: fixed;
		bottom: 0;
		right: 20px;
		display: block;
		padding: 0 20px;
		box-shadow: 0 2px 15px rgba(0, 0, 0, 0.15);
		margin-top: 0;
		line-height: 60px;
	}

	:global(.z-livechat .z-livechat-btn.z-livechat-btn-r) {
		border-radius: 100px;
	}

	:global(.z-livechat .z-livechat-btn) {
		background: #fff;
		margin-top: 50px;
		box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
		transition: 0.3s all;
		border-radius: 5px 5px 0 0;
		line-height: 1;
		color: #247ce9;
		text-align: center;
		cursor: pointer;
		box-sizing: border-box;
		padding: 10px 20px;
		word-break: keep-all;
		white-space: pre;
		overflow: hidden;
		display: inline-block;
	}

	:global(.hidden) {
		display: none;
	}

	:global(img.emoji) {
		width: 20px;
	}

	:global(.z-livechat-svg-icon.z-livechat-svg-icon-small) {
		width: 30px;
		height: 30px;
	}

	:global(.z-livechat-svg-icon.z-livechat-svg-icon-smallest) {
		width: 20px;
		height: 20px;
	}

	:global(form.rating-form textarea) {
		height: 50px;
		outline: none;
		font-family: inherit;
	}

	:global(form.rating-form button) {
		font-family: inherit;
		font-size: 100%;
		padding: 0.5em 1em;
		color: #fff;
		border: 0 rgba(0, 0, 0, 0);
		background-color: #4397f1;
		text-decoration: none;
		border-radius: 4px;
	}

	:global(form.rating-form svg) {
		fill: rgb(242, 242, 242);
		height: 3.6rem;
		width: 3.6rem;
		margin: 0.2rem;
	}

	:global(form.rating-form input[type="radio"]) {
		position: absolute;
		opacity: 0;
	}

	:global(form.rating-form input[type="radio"] + svg) {
		transition: all 0.2s;
		cursor: pointer;
		border-radius: 50%;
		filter: grayscale(0.4);
	}

	:global(form.rating-form input[type="radio"]:hover + svg),
	:global(form.rating-form input[type="radio"]:checked + svg),
	:global(form.rating-form input[type="radio"]:focus + svg) {
		box-shadow: 0 0 0 5px #fff;
		filter: grayscale(0);
	}

	:global(form.z-livechat-form) {
		margin: 0;
	}

	:global(.z-livechat-header svg) {
		margin-right: 10px;
		fill: #fff;
	}

	:global(.z-livechat-header) {
		display: flex;
		align-items: center;
		position: relative;
		padding: 0 10px;
		box-sizing: border-box;
		color: #fff;
		background: #64b2ff;
		line-height: 50px;
	}

	:global(.z-livechat-inner.z-livechat-status-chat_ended) {
		padding: 0 1em;
	}

	:global(.z-livechat-inner.z-livechat-status-chat_waiting) {
		background: #fff;
	}

	:global(.z-livechat-waiting) {
		padding: 6em 1em 1em 1em;
		text-align: center;
		position: absolute;
		z-index: 11;
		margin: 1px;
		width: 100%;
		box-sizing: border-box;
	}

	:global(.z-livechat-inner.z-livechat-is-ended) {
		background: linear-gradient(#35a2f3, #e5f4ff);
		text-align: center;
		padding-top: 30px;
		font-weight: bold;
		color: #fff;
	}

	:global(.z-livechat-inner.z-livechat-is-offline:before) {
		z-index: 1;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		text-align: center;
		line-height: 380px;
		content: "Sorry, we are currently offline";
		background: rgba(255, 255, 255, 0.9);
		position: absolute;
		font-weight: bold;
	}

	:global(.z-livechat-inner) {
		bottom: 70px;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
		background: #f8f8f8;
		border-radius: 5px;
		text-align: left;
		font-size: 16px;
		color: #333;
		width: 300px;
		box-sizing: border-box;
		position: relative;
		cursor: auto;
		overflow: hidden;
		animation: 0.1s fly ease-out;
	}

	:global(.z-livechat-inner.z-livechat-inner-maximized) {
		width: 450px;
	}

	:global(.z-livechat-inner.z-livechat-inner-maximized .z-livechat-messages) {
		height: 500px;
	}

	@keyframes fly {
		0% {
			opacity: 0;
			transform: translateY(30px);
		}

		100% {
			opacity: 1;
			transform: translateY(0);
		}
	}

	:global(.z-livechat-inner .z-livechat-messages) {
		line-height: 1.3;
		border-radius: 0;
		width: 100%;
		height: 282px;
		padding: 5px;
		box-sizing: border-box;
		overflow: auto;
		overflow-anchor: auto;
	}

	:global(.z-livechat-inner .z-livechat-message-list) {
		border-radius: 5px 5px 0 0;
		overflow: auto;
		overflow-anchor: auto;
		list-style-type: none;
		margin: 10px 0;
		padding: 0;
	}

	:global(.z-livechat-message-list li.z-livechat-message-error) {
		justify-content: center;
	}

	:global(.z-livechat-message-list li.z-livechat-message-error div) {
		color: rgb(255, 75, 75);
		font-size: 0.9rem;
		border-radius: 2px;
	}

	:global(.z-livechat-message-list li) {
		margin: 3px;
		display: flex;
		vertical-align: bottom;
		align-self: flex-end;
		justify-content: flex-end;
	}

	:global(.z-livechat-message-list li div) {
		max-width: 80%;
		padding: 6px 10px;
		border-radius: 10px 10px 0 10px;
		flex-shrink: 0;
		display: inline-flex;
		flex-direction: column;
		flex-basis: auto;
		align-items: stretch;
		word-break: break-word;
		border-width: 0;
		/*border: solid 1px #e6e6e6;*/
		box-shadow: 1px 1px 1px rgb(0 0 0 / 15%), 0 0 1px #aaaaaaee;
		color: #333;
		background: #fff;
	}

	:global(.z-livechat-message-list li div p *) {
		text-decoration: none;
		color: #156dca;
	}

	:global(.z-livechat-message-list li div p *:hover) {
		text-decoration: underline;
	}

	:global(.z-livechat-message-list li div p) {
		margin: 0;
	}

	:global(.z-livechat-message-list li.z-livechat-from-name) {
		margin: 15px 7px 7px 7px;
		font-size: 90%;
		color: #567;
		background: inherit;
	}

	:global(.z-livechat-message-list li.z-livechat-from-admin) {
		align-self: flex-start;
		justify-content: flex-start;
	}

	:global(.z-livechat-message-list li.z-livechat-from-admin div) {
		border-radius: 0 10px 10px 10px;
	}

	:global(.z-livechat-inner .z-livechat-input) {
		width: 100%;
		padding: 0;
		box-sizing: border-box;
		position: relative;
		border-top: solid 1px #ddd;
		bottom: 0;
		height: 69px;
	}

	:global(.z-livechat-textarea-buttons) {
		position: absolute;
		right: 5px;
		bottom: 10px;
		display: flex;
	}

	:global(.z-livechat-inner .z-livechat-textarea-buttons button),
	:global(.z-livechat-inner .z-livechat-textarea-buttons label) {
		border: none;
		background: inherit;
		cursor: pointer;
		font-size: 15px;
		color: #247ce9;
		stroke: #247ce9;
		fill: #247ce9;
		font-weight: bold;
	}

	:global(.z-livechat-inner .z-livechat-textarea-buttons button .z-livechat-svg-icon),
	:global(.z-livechat-inner .z-livechat-textarea-buttons label .z-livechat-svg-icon) {
		width: 25px;
		height: 25px;
	}

	:global(.z-livechat-inner .z-livechat-textarea-buttons button),
	:global(.z-livechat-inner .z-livechat-textarea-buttons label) {
		stroke: #aaa;
		fill: #aaa;
		transition: 0.3s all;
		height: 30px;
	}

	:global(.z-livechat-inner .z-livechat-textarea-buttons button:hover),
	:global(.z-livechat-inner .z-livechat-textarea-buttons label:hover) {
		stroke: #247ce9;
		fill: #247ce9;
	}

	:global(.z-livechat-inner .z-livechat-textarea-buttons label.button-attach-file input) {
		display: none;
	}

	:global(.z-livechat-inner .z-livechat-textarea-buttons button.button-send-message) {
		fill: none;
	}

	:global(.z-livechat-status-active .z-livechat-input) {
		display: block;
	}

	:global(.z-livechat-inner .z-livechat-input #z-livechat-input) {
		line-height: 1.3;
		border-width: 0;
		border-radius: 0 0 5px 5px;
		font-size: 16px;
		padding: 10px 60px 10px 10px;
		outline: none;
		font-family: sans-serif;
	}	

	:global(.visually-hidden) {
		height: 0.1px;
		width: 0.1px;
		opacity: 0;
		z-index: -1;
		position: absolute;
		overflow: hidden;
	}

</style>
