import * as store from './store.js';
import uuid from '../public/uuid';
import { writable } from 'svelte/store';


class Store {

	constructor(stores) {
		this.stores = stores;
		this.values = {};
	}

	subscribe(key) {
		this.stores[key].subscribe((value) => {
			this.values[key] = value;
		});
	}

	set(data) {

		// if ('appstatus' in data) console.log('set', data);

		for (const key in data) {
			if (typeof (this.stores[key]) == 'undefined') {
				this.stores[key] = writable(data[key]);
			} else {
				this.stores[key].set(data[key]);
			}

			if (typeof (this.values[key]) == 'undefined') {
				this.subscribe(key);
			}
		}
	}

	get(key) {
		if (key) {
			return this.values[key];
		} else {
			return this.values;
		}
	}
}

export default class Livechat {

	constructor() {
		this.host = this.getElementParameter('host');
		this.pid = this.getElementParameter('pid');
		this.username = this.getElementParameter('username');
		this.useremail = this.getElementParameter('useremail');
		this.pong = Date.now();
		this.signedUrlStack = [];
		this.store = new Store(store);

		// create a new userid or get it from the cookie
		const userid = this.getCookie('chatuser') || uuid();
		const chatid = this.chatId();
		this.setCookie('chatuser', userid);
		this.store.set({
			userid
		});
		this.store.set({
			chatid
		});
		this.store.set({
			host: this.host
		});
		this.store.set({
			pid: this.pid
		});
		this.store.set({
			username: this.username
		});
		this.store.set({
			useremail: this.useremail
		});

		// Create WebSocket connection.
		this.createWebsocket();
	}

	resetStoreToDefaultValues() {
		const feedbackChatId = this.store.get('chatid');
		const chatid = this.chatId(true);

		this.store.set({
			chatid
		});
		this.store.set({
			feedbackChatId
		});
		this.store.set({
			feedbackSuccess: null
		});
		this.store.set({
			feedbackText: ''
		});
		this.setMessages([]);
	}

	getSignedUrl(rawdata) {
		const {
			userid,
			host,
			pid,
			feedbackChatId
		} = this.store.get();

		const data = {
			c: 'get-signed-url',
			file: rawdata.file,
			userid,
			chatid: feedbackChatId,
			host,
			pid
		};

		this.signedUrlStack.push(rawdata);		
		this.socket.send(JSON.stringify(data));
	}

	sendFeedback(data) {
		const {
			userid,
			host,
			pid,
			feedbackChatId
		} = this.store.get();
		Object.assign(data, {
			userid,
			chatid: feedbackChatId,
			host,
			pid
		});
		this.socket.send(JSON.stringify(data));
	}

	send(data) {
		const {
			userid,
			chatid,
			host,
			pid,
			useremail,
			username
		} = this.store.get();
		Object.assign(data, {
			userid,
			chatid,
			host,
			pid
		});

		if (typeof data.username == 'undefined') {
			Object.assign(data, {
				username
			});
		}

		if (typeof data.useremail == 'undefined') {
			Object.assign(data, {
				useremail
			});
		}

		try {
			if (this.socket.readyState == WebSocket.OPEN) {
				this.socket.send(JSON.stringify(data));
			}
		} catch (err) {

		}
	}

	setMessages(messages) {
		this.store.set({
			messages: messages.slice()
		});
	}

	chatId(forceNew) {
		// create a new chatid or get it from the cookie
		const chatid = forceNew ? uuid() : this.getCookie('chatid') || uuid();
		this.setCookie('chatid', chatid);

		return chatid;
	}

	setCookie(name, value) {
		document.cookie = name + "=" + (value || "") + "; path=/";
	}

	getCookie(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');

		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		}

		return null;
	}

	// get customer agent's status, periodically
	heartbeatSend() {
		this.send({
			c: 'get_agent'
		});
	}

	// check whether we have contacted the server for the last (12s)
	// if server has been unresponsive, set it as offline
	heartbeatCheck() {
		const now = Date.now()

		if (now - this.pong > 12000) {
			this.store.set({
				admin_available: false
			});
		} else {

		}
	}

	createWebsocket() {
		let events = {};
		let wasAlreadyOpen = false;

		try {
			if (window.livechat_socket) {
				this.socket = window.livechat_socket;
				events = livechat_socket_events;

				this.socket.removeEventListener('open', events['open']);
				this.socket.removeEventListener('close', events['close']);
				this.socket.removeEventListener('message', events['message']);

				if (this.socket.readyState == WebSocket.OPEN) {
					wasAlreadyOpen = true;
				}
			} else {
				this.socket = new WebSocket('wss://' + this.host + '.aamu.app/ws2');
				window.livechat_socket = this.socket;
			}
		} catch (err) {
			console.error(err.toString());
		}

		events['open'] = (event) => {
			this.store.set({
				online: true
			});
			this.send({
				c: 'get_agent'
			});

			if (this.heartbeatSendInterval) {
				clearInterval(this.heartbeatSendInterval);
			}

			if (this.heartbeatCheckInterval) {
				clearInterval(this.heartbeatCheckInterval);
			}

			this.heartbeatSendInterval = setInterval(this.heartbeatSend.bind(this), 10000);
			this.heartbeatCheckInterval = setInterval(this.heartbeatCheck.bind(this), 1000);
		};

		// Connection close
		events['close'] = (event) => {
			this.store.set({
				online: false
			});
			window.livechat_socket = null;
			setTimeout(this.createWebsocket.bind(this), 5000);
		};

		// Listen for messages
		events['message'] = (event) => {
			let data;

			try {
				data = JSON.parse(event.data);
			} catch (err) {
				console.error(err);
				return;
			}

			// console.log('Message from server ', JSON.stringify(data));
			// console.log('Message from server ', data);

			if (data.status) {
				this.store.set({
					appstatus: data.status
				});
			}

			switch (data.c) {
				case 'endchat':
					this.store.set({
						appstatus: 'chat_ended'
					});
					this.resetStoreToDefaultValues();
					break;

				case 'change':
					this.store.set({
						admin_available: data.admin_available || false
					});
					break;

				case 'waiting_in_line_count':
					this.store.set({
						waiting_in_line_count: data.count
					});
					break;

				case 'get_agent':
					this.pong = Date.now();

					this.store.set({
						admin_available: data.admin_available || false
					});

					// did we get any messages from the old chat
					if (data.messages && data.messages.length) {
						this.setMessages(data.messages);
					}

					if (data.vocab) {
						this.store.set({
							vocab: data.vocab
						});
					}

					this.store.set({
						server_contacted: true
					});

					if (data.chatid && data.status == 'chat_ended') {
						this.store.set({
							appstatus: 'chat_ended'
						});
						this.resetStoreToDefaultValues();
					}

					break;

				case 'email_done':
					this.store.set({
						appstatus: 'emailing_email_sent'
					});
					break;

				case 'email_failed':
					this.store.set({
						appstatus: 'emailing_email_failed'
					});
					break;

				case 'reg':
					break;

				case 'get-signed-url':
					const latestAction = this.signedUrlStack.pop();

					if (latestAction) {
						latestAction.resolvefn({ urls: data.urls, method: 'PUT' });
					}					
					break;

				case 'msg':
					const chatid = this.store.get('chatid');

					// do we have the correct chatid
					if (data.chatid == chatid) {
						const messages = this.store.get('messages') || [];
						let newMessages;

						// Is this msg from us, and is the html field updated (created)
						for (var i = 0; i < messages.length; i++) {
							if (messages[i].id == data.id) {
								messages[i].html = data.html;
								newMessages = true;
							}
						}

						// Or is this from admin (support staff)
						if (!newMessages && data.admin) {
							messages.push(data);
							newMessages = true;
						}

						if (newMessages) {
							this.setMessages(messages);
						}
					}

					break;
			}
		};

		this.socket.addEventListener('open', events['open']);
		this.socket.addEventListener('close', events['close']);
		this.socket.addEventListener('message', events['message']);
		window.livechat_socket_events = events;

		if (wasAlreadyOpen) {
			this.store.set({
				online: true
			});
			this.send({
				c: 'get_agent'
			});
		}
	}

	getElementParameter(which) {
		const element = document.querySelector('aamu-livechat');

		if (element) {
			return element.dataset[which];
		}
	}

}